import { useTheme } from "@emotion/react";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import { formatTimestamp } from "@ternary/api-lib/ui-lib/charts/utils";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import { groupBy, keyBy, uniqBy } from "lodash";
import React, { useMemo } from "react";
import copyText from "../copyText";

type TableData = {
  monthOverMonthAbsolute: number;
  monthOverMonthPercent: number;
  tenantName: string;
};

const columnHelper = createColumnHelper<TableData>();

type MspSpendData = {
  billedCost: number;
  tenantId: string;
  tenantName: string;
  timestamp: string;
};

interface Props {
  data: MspSpendData[];
  isLoading: boolean;
}

export default function MspMonthlyTrendsTable(props: Props) {
  const theme = useTheme();

  const columns = useMemo(() => {
    const timestampColumns = uniqBy(props.data, "timestamp")
      .sort()
      .map((datum) =>
        columnHelper.accessor((tableData) => tableData[datum.timestamp], {
          cell: (cell) => {
            const number = Number(cell.getValue());

            return isNaN(number) ? "--" : formatCurrency({ number });
          },
          header: formatTimestamp(datum.timestamp, "MM/yy"),
        })
      );

    return [
      columnHelper.accessor("tenantName", {
        header: copyText.tableHeaderName,
      }),
      ...timestampColumns,
      columnHelper.accessor("monthOverMonthAbsolute", {
        cell: (cell) => formatCurrency({ number: cell.getValue() }),
        header: copyText.tableHeaderMomAbsolute,
      }),
      columnHelper.accessor("monthOverMonthPercent", {
        cell: (cell) => {
          const isPositive = cell.getValue() > 0;
          return (
            <Flex alignItems="center" justifyContent="space-between" width={70}>
              <Text>{formatPercentage(cell.getValue())}</Text>
              <Icon
                color={
                  isPositive ? theme.feedback_negative : theme.feedback_positive
                }
                icon={isPositive ? faArrowUp : faArrowDown}
              ></Icon>
            </Flex>
          );
        },
        header: copyText.tableHeaderMomPercent,
      }),
    ];
  }, [props.data]);

  const data = useMemo(() => formatDataForDisplay(props.data), [props.data]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        sorting: [{ id: "monthOverMonthAbsolute", desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

export function formatDataForDisplay(data: MspSpendData[]) {
  const [m3TimestampKey, m2TimestampKey, m1Timestampkey] = Object.keys(
    keyBy(data, "timestamp")
  );

  const monthlyDataGroupedByTenantName = groupBy(data, "tenantName");

  const mspSpendDataMonthly = Object.entries(
    monthlyDataGroupedByTenantName
  ).map(([tenantName, data]) => {
    const dataKeyedByTimestamp = keyBy(data, "timestamp");

    const month1 = dataKeyedByTimestamp[m1Timestampkey];
    const month2 = dataKeyedByTimestamp[m2TimestampKey];
    const month3 = dataKeyedByTimestamp[m3TimestampKey];

    const month1Timetsamp = month1?.timestamp ?? m1Timestampkey;
    const month2Timestamp = month2?.timestamp ?? m2TimestampKey;
    const month3Timestamp = month3?.timestamp ?? m3TimestampKey;

    const month1BilledCost = month1?.billedCost ?? 0;
    const month2BilledCost = month2?.billedCost ?? 0;
    const month3BilledCost = month3?.billedCost ?? 0;

    let monthOverMonthPercent =
      (month1BilledCost - month2BilledCost) / month2BilledCost;

    let monthOverMonthAbsolute = month1BilledCost - month2BilledCost;

    if (month1BilledCost === 0) {
      monthOverMonthPercent =
        (month2BilledCost - month3BilledCost) / month3BilledCost;

      monthOverMonthAbsolute = month2BilledCost - month3BilledCost;
    }

    return {
      [month1Timetsamp]: month1BilledCost,
      [month2Timestamp]: month2BilledCost,
      [month3Timestamp]: month3BilledCost,
      monthOverMonthAbsolute,
      monthOverMonthPercent,
      tenantName,
    };
  });

  return mspSpendDataMonthly;
}
